import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../public/fonts/UolText/uol-text-regular.woff\",\"display\":\"swap\",\"variable\":\"--uol-text\"}],\"variableName\":\"UOL_TEXT\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../public/fonts/UolText/uol-text-bold.woff\",\"display\":\"swap\",\"variable\":\"--uol-bold-text\"}],\"variableName\":\"UOL_BOLD_TEXT\"}");
;
import(/* webpackMode: "eager" */ "/app/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/page-footer/page-footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/page-header/page-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/page-navbar/navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/context/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/layout/base/base-layout.tsx");
