'use client'

import { ReactNode, useCallback, useState } from 'react'
import { createContext } from 'use-context-selector'

const DEFAULT_GEOLOCATION_DATA = {
  accuracy: 0,
  altitude: 0,
  altitudeAccuracy: 0,
  heading: 0,
  latitude: 0,
  longitude: 0,
  speed: 0,
  timestamp: 0
}

type GeolocationContextProps = {
  children: ReactNode
}

type Geolocation = GeolocationCoordinates & { timestamp: EpochTimeStamp }

type Permission = 'granted' | 'denied' | 'prompt'

type GeolocationContext = {
  geolocation: Geolocation
  onSetGeolocation: (geolocation: Geolocation) => void
  permission: Permission
  onSetPermission: (permission: Permission) => void
}

export const GeolocationContext = createContext({} as GeolocationContext)

export const GeolocationProvider = ({ children }: GeolocationContextProps) => {
  const [geolocation, setGeolocation] = useState<Geolocation>(
    DEFAULT_GEOLOCATION_DATA
  )
  const [permission, setPermission] = useState<Permission>('prompt')

  const onSetGeolocation = useCallback((geolocation: Geolocation) => {
    setGeolocation(geolocation)
  }, [])

  const onSetPermission = useCallback((permission: Permission) => {
    setPermission(permission)
  }, [])

  return (
    <GeolocationContext.Provider
      value={{ geolocation, onSetGeolocation, permission, onSetPermission }}
    >
      {children}
    </GeolocationContext.Provider>
  )
}
