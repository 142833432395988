'use client'

import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PropsWithChildren, useState } from 'react'

import { AdvertiseProvider } from './advertise-context'
import { CityProvider } from './city-context'
import { GeolocationProvider } from './geolocation-context'
import { PageHistoryProvider } from './page-history-context'
import { PartnershipProvider } from './partnership-context'
import { UserProvider } from './user-context'

const providers = [
  AdvertiseProvider,
  UserProvider,
  CityProvider,
  PartnershipProvider,
  PageHistoryProvider,
  GeolocationProvider
]

const ComposeProviders = (providers) => {
  return providers.reduce((Previous, Current) => {
    const Composed = ({ children }: PropsWithChildren) => (
      <Previous>
        <Current>{children}</Current>
      </Previous>
    )
    return Composed
  })
}

const ComposedProviders = ComposeProviders(providers)

const Provider = ({ children }: PropsWithChildren) => {
  const [client] = useState(
    new QueryClient({ defaultOptions: { queries: { staleTime: 5000 } } })
  )

  return (
    <QueryClientProvider client={client}>
      <ComposedProviders>{children}</ComposedProviders>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default Provider
