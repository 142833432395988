'use client'

import { usePathname } from 'next/navigation'
import { ReactNode, useCallback, useState } from 'react'
import { createContext } from 'use-context-selector'

export type PageHistory = {
  current: string
  previous: string
  previousURL: string
}

type PageHistoryContext = {
  pageHistory: PageHistory
  onSetPageHistory: (pagename: string) => void
}

export type PageHistoryContextProps = {
  children: ReactNode
}

export const PageHistoryContext = createContext({} as PageHistoryContext)

export const PageHistoryProvider = ({ children }: PageHistoryContextProps) => {
  const pathname = usePathname()
  const [pageHistory, setPageHistory] = useState<PageHistory>({
    current: '',
    previous: '',
    previousURL: ''
  })

  const onSetPageHistory = useCallback(
    (pagename: string) => {
      setPageHistory((state) => {
        return {
          current: pagename,
          previous: state?.current || pagename,
          previousURL: (pathname && pathname) || ''
        }
      })
    },
    [pathname]
  )

  return (
    <PageHistoryContext.Provider
      value={{
        pageHistory,
        onSetPageHistory
      }}
    >
      {children}
    </PageHistoryContext.Provider>
  )
}
